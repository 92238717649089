@charset "UTF-8";
.signin {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  text-align: left;
  font-size: 14px;
  margin-top: 20px;
}
.signin .title {
    color: #333333;
    font-weight: bold;
    font-size: 14px;
    margin-bottom: 0;
}
.signin .top-btns {
    margin-bottom: 8px;
}
.signin .left,
  .signin .right {
    height: 100%;
}
.signin .left {
    width: 910px;
}
.signin .left .train-paln-info {
      background: #ffffff;
      border: #e5e5e5 1px solid;
      padding: 20px;
      -webkit-box-sizing: border-box;
              box-sizing: border-box;
      line-height: 28px;
}
.signin .left .train-paln-info .info-wrap {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
            -ms-flex-pack: justify;
                justify-content: space-between;
}
.signin .left .train-paln-info .info-wrap .line {
          display: inline-block;
          width: 2px;
          height: 110px;
          background: #e5e5e5;
}
.signin .left .train-paln-info .info-wrap .manager-role {
          width: 300px;
}
.signin .left .train-paln-info .info-wrap .manager-role li {
            height: 28px;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: justify;
                -ms-flex-pack: justify;
                    justify-content: space-between;
}
.signin .left .train-paln-info .info-wrap .manager-role li span {
              margin-right: 18px;
}
.signin .left .train-paln-info .info-wrap .manager-role li span label {
                display: inline-block;
                width: 88px;
                text-align: right;
}
.signin .left .train-paln-info .info-wrap .manager-role li button {
              padding: 0;
}
.signin .left .train-paln-info .info-wrap .manager-role .meeting-info-cell span {
            white-space: nowrap;
            /* 防止文字换行 */
            overflow: hidden;
            /* 隐藏溢出内容 */
            text-overflow: ellipsis;
            /* 显示省略号 */
            -webkit-box-sizing: border-box;
                    box-sizing: border-box;
}
.signin .left .sign-tips-wrapper {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      height: 40px;
      line-height: 40px;
      background: #ffffff;
      border: #e5e5e5 1px solid;
      border-bottom: none;
      -webkit-box-sizing: border-box;
              box-sizing: border-box;
      color: red;
      padding-left: 20px;
      margin-top: 10px;
}
.signin .left .sign-tips-wrapper .notice-bar {
        padding-left: 100%;
        word-break: keep-all;
        white-space: nowrap;
        -webkit-animation: animation-notice 50s linear infinite both;
                animation: animation-notice 50s linear infinite both;
}
.signin .left .sign-tips-wrapper .notice-box {
        text-align: right;
        -webkit-box-flex: 1;
            -ms-flex: 1;
                flex: 1;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
            -ms-flex-direction: row;
                flex-direction: row;
        -ms-flex-wrap: nowrap;
            flex-wrap: nowrap;
        overflow: hidden;
}
.signin .left .sign-tips-wrapper .white-nowrap {
        word-break: keep-all;
        white-space: nowrap;
}
.signin .left .tips {
      margin: 10px 0;
}
.signin .left .sign-list-wrap {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
          -ms-flex-pack: justify;
              justify-content: space-between;
}
.signin .left .sign-list-wrap .sign-list {
        padding: 20px;
        -webkit-box-sizing: border-box;
                box-sizing: border-box;
        width: 338px;
        background: #ffffff;
        border: #e5e5e5 1px solid;
}
.signin .left .sign-list-wrap .sign-list .search-input {
          width: 300px;
          height: 30px;
          line-height: 30px;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
              -ms-flex-align: center;
                  align-items: center;
          margin: 18px 0;
}
.signin .left .sign-list-wrap .sign-list .search-input .el-input {
            width: 236px;
}
.signin .left .sign-list-wrap .sign-list .search-input .el-input .el-input__inner {
              border-radius: 0;
              height: 32px;
              padding-left: 8px;
}
.signin .left .sign-list-wrap .sign-list .search-input button {
            display: inline-block;
            height: 32px;
            width: 60px;
            background: #409eff;
            color: #ffffff;
            border: none;
            list-style: none;
            border-radius: 0;
            padding: 0;
}
.signin .left .sign-list-wrap .verify {
        width: 558px;
        background: #ffffff;
        border: #e5e5e5 1px solid;
        padding: 20px;
        -webkit-box-sizing: border-box;
                box-sizing: border-box;
}
.signin .left .sign-list-wrap .verify .btns {
          text-align: right;
}
.signin .left .sign-list-wrap .verify .update-user-face {
          margin-left: 15px;
}
.signin .left .sign-list-wrap .verify .start-to-clazz {
          height: 20px;
          padding: 20px 0 20px 0;
          color: #eb2121;
          font-weight: bold;
}
.signin .left .sign-list-wrap .verify .start-to-clazz .to-clazz-close {
            line-height: 100%;
            padding-top: 3px;
}
.signin .left .sign-list-wrap .verify .scanning {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-pack: justify;
              -ms-flex-pack: justify;
                  justify-content: space-between;
          margin-top: 10px;
}
.signin .left .sign-list-wrap .verify .scanning .register-photo,
          .signin .left .sign-list-wrap .verify .scanning .scanning-photo {
            width: 200px;
            height: 248px;
            text-align: center;
}
.signin .left .sign-list-wrap .verify .scanning .register-photo img,
            .signin .left .sign-list-wrap .verify .scanning .scanning-photo img {
              display: inline-block;
              width: 180px;
              height: 229px;
}
.signin .left .sign-list-wrap .verify .scanning .verify-tip-box {
            width: 120px;
            text-align: center;
            padding-top: 34px;
}
.signin .left .sign-list-wrap .verify .scanning .verify-tip-box .tip-text {
              color: #eb2121;
              font-weight: bold;
              font-size: 16px;
}
.signin .left .sign-list-wrap .verify .scanning .verify-tip-box .verify-identity-text {
              height: 42px;
              overflow: hidden;
              color: #777777;
              margin: 5px 0;
}
.signin .left .sign-list-wrap .verify .scanning .verify-tip-box .verify-state-text {
              color: #17bdb3;
              margin-bottom: 10px;
}
.signin .left .sign-list-wrap .verify .scanning-tips {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-pack: justify;
              -ms-flex-pack: justify;
                  justify-content: space-between;
}
.signin .left .sign-list-wrap .verify .scanning-tips h4 {
            font-weight: normal;
            font-size: 16px;
}
.signin .left .sign-list-wrap .verify .scanning-tips .photo,
          .signin .left .sign-list-wrap .verify .scanning-tips .tips-list {
            margin-top: 20px;
}
.signin .left .sign-list-wrap .verify .scanning-tips .photo ul,
            .signin .left .sign-list-wrap .verify .scanning-tips .tips-list ul {
              line-height: 25px;
}
.signin .left .sign-list-wrap .verify .scanning-tips .wechat-qrCode {
            max-width: 520px;
            margin-top: 30px;
}
.signin .left .sign-list-wrap .verify .qrcode {
          width: 244px;
          margin: 0 auto;
}
.signin .left .sign-list-wrap .verify .qrcode p {
            text-align: center;
}
.signin .left .sign-list-wrap .verify .no-sign-in-type {
          width: 100%;
          min-height: 200px;
          line-height: 200px;
          text-align: center;
          font-size: 18px;
          color: red;
          font-weight: bolder;
}
.signin .left .sign-list-wrap .verify .no-sign-in-face {
          font-size: 18px;
          color: red;
          font-weight: bolder;
          margin-top: 50px;
}
.signin .left .sign-list-wrap .verify #signInUserBox {
          margin: 0 auto;
}
.signin .right {
    width: 280px;
}
.signin .right .study-button-wrap .study-button-container {
      cursor: pointer;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      height: 50px;
      background-color: #ffa112;
      border: 1px solid #e6e6e6;
      border-radius: 4px;
      -webkit-box-sizing: border-box;
              box-sizing: border-box;
      color: #ffffff;
      font-size: 18px;
}
.signin .right .study-button-wrap .study-button-container .button-icon {
        width: 12px;
        height: 16px;
}
.signin .right .study-button-wrap .study-button-container .button-title {
        margin-left: 8px;
}
.signin .right .study-button-wrap .el-loading-mask .el-loading-spinner .circular {
      width: 20px;
}
.signin .right .study-button-wrap .el-loading-mask .el-loading-spinner .path {
      stroke: #ffa112;
}
.signin .right .sign-recorde-wrap {
      border: #e5e5e5 1px solid;
      background: #ffffff;
      padding: 20px;
      margin-top: 10px;
      -webkit-box-sizing: border-box;
              box-sizing: border-box;
}
.signin .right .sign-recorde-wrap .title {
        margin-bottom: 18px;
}
.signin .right .sign-recorde-wrap .sign-in-box-no-data {
        min-height: 60px;
        line-height: 60px;
        color: #eb2121;
}
.signin .right .sign-recorde-wrap .qrcode {
        text-align: center;
        margin-bottom: 20px;
}
.signin .right .sign-recorde-wrap .record-list li {
        border-bottom: #e5e5e5 1px solid;
        padding: 10px 0;
}
.signin .right .sign-recorde-wrap .record-list li .record {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          margin-bottom: 13px;
}
.signin .right .sign-recorde-wrap .record-list li .record span {
            display: inline-block;
}
.signin .right .sign-recorde-wrap .record-list li .record .name {
            -webkit-box-flex: 1;
                -ms-flex: 1;
                    flex: 1;
}
.signin .right .sign-recorde-wrap .record-list li .record .role,
          .signin .right .sign-recorde-wrap .record-list li .record .sign-way {
            width: 70px;
}
.signin .right .sign-recorde-wrap .record-list li .record .role {
            padding: 0 15px;
}
.signin .right .sign-recorde-wrap .record-list li .record .sign-way {
            text-align: right;
}
.signin .el-pagination {
    text-align: center;
    margin-top: 10px;
}
.signin .close-button {
    margin-top: 8px;
}
@-webkit-keyframes animation-notice {
0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
}
100% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
}
}
@keyframes animation-notice {
0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
}
100% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
}
}
