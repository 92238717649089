.take-photo-wrap .el-dialog__header {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  text-align: left;
  border-bottom: 1px solid #e6e6e6;
  margin-left: 20px;
  margin-right: 20px;
  padding: 12px 0px;
  color: #333333;
  font-size: 16px;
}
.take-photo-wrap .el-dialog__header .el-dialog__headerbtn {
    top: 16px;
}
.take-photo-wrap .el-dialog__body {
  padding: 40px 50px 30px 50px;
}
.take-photo-wrap .el-dialog__body .content-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.take-photo-wrap .el-dialog__body .content-wrap .camera-zone-wrap {
      position: relative;
}
.take-photo-wrap .el-dialog__body .content-wrap .camera-zone-wrap .camera-zone {
        position: absolute;
        background: #000000;
        border: 1px solid #E6E6E6;
        border-radius: 4px;
        overflow: hidden;
}
.take-photo-wrap .el-dialog__body .content-wrap .camera-zone-wrap .camera-zone video {
          position: absolute;
          top: 0;
}
.take-photo-wrap .el-dialog__body .content-wrap .camera-zone-wrap .camera-photo {
        position: absolute;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        border-radius: 4px;
        overflow: hidden;
        width: 100%;
        height: 100%;
}
.take-photo-wrap .el-dialog__body .content-wrap .right-button-group {
      -webkit-box-flex: 1;
          -ms-flex: 1;
              flex: 1;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
}
.take-photo-wrap .el-dialog__body .content-wrap .right-button-group .el-button {
        margin-left: 0px;
        margin-top: 10px;
}
.take-photo-wrap .el-dialog__body .button-group {
    margin-top: 30px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    font-size: 14px;
    font-family: MicrosoftYaHei;
}
.take-photo-wrap .el-dialog__body .button-group .el-button {
      width: 120px;
      height: 36px;
      padding: 0px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      border-radius: 4px;
      -webkit-box-sizing: border-box;
              box-sizing: border-box;
}
.take-photo-wrap .el-dialog__body .button-group .cancle-button {
      background: #e6e6e6;
      color: #333333;
}
.take-photo-wrap .el-dialog__body .button-group .ensure-button {
      background: #409eff;
      color: white;
      margin-left: 20px;
}
